import { useNavigate } from "react-router-dom";
import CheckBoxFeature from "../CheckBoxFeature/CheckBoxFeature";
import RoundedButton from "../RoundedButton/RoundedButton";
import "./ServicesSection.css";
import InfoChip from "../InfoChip/InfoChip";

export default function ServicesSection() {
  const navigate = useNavigate();
  return (
    <div className="ServicesSection--MainContainer">
      <text
        style={{ fontFamily: "Montserrat", fontSize: "24px", color: "#FFB800" }}
      >
        SERVICIOS
      </text>
      <text
        style={{
          fontFamily: "Montserrat",
          fontSize: "16px",
          color: "white",
          marginBottom: "20px",
        }}
      >
        ¿QUÉ PODEMOS HACER POR TÍ?
      </text>
      {/* <CheckBoxFeature feature="Fabricación e instalación de paneles SIP" />
      <CheckBoxFeature feature="Construcción de casas" />
      <CheckBoxFeature feature="Hormigones estampados" />
      <CheckBoxFeature feature="Urbanización" />
      <CheckBoxFeature feature="Gasfitería" />
      <CheckBoxFeature feature="Carpintería" />
      <CheckBoxFeature feature="Albañilería" />
      <CheckBoxFeature feature="Terrazas" />
      <CheckBoxFeature feature="Quinchos" />
      <CheckBoxFeature feature="Paisajismos" />
      <CheckBoxFeature feature="Electricidad" /> */}
      <div style={{display:"flex",flexWrap:"wrap",justifyContent: "center"}}>
        <InfoChip
          title="Fabricación e instalación de paneles SIP"
          description="Fabricamos paneles a SIP a la medida de sus necesidades. Tambien realizamos la instalación de los mismos. "
          backgroundImage="https://alfasip.cl/public/construccion%20panel%20sip.PNG"
          actionButtonClick={() => {}}
          actionButtontext="Conoce Más"
        />
        <InfoChip
          title="Construcción de casas"
          description="Ofrecemos servicios integrales de construcción de casas, desde el diseño arquitectónico hasta la entrega llave en mano. Nuestro equipo experto garantiza calidad, eficiencia y cumplimiento de plazos. Su sueño hecho realidad."
          backgroundImage="https://alfasip.cl/public/servicioConstruccionCasas.jpg"
          actionButtonClick={() => {}}
          actionButtontext="Conoce Más"
        />
        <InfoChip
          title="Hormigones estampados"
          description="Nuestro servicio de construcción de hormigón estampado SIP ofrece soluciones duraderas y estéticamente atractivas para pisos, veredas y superficies exteriores. Calidad, resistencia y diseños personalizados para satisfacer sus necesidades."
          backgroundImage="https://alfasip.cl/public/construccion%20panel%20sip.PNG"
          actionButtonClick={() => {}}
          actionButtontext="Conoce Más"
        />
      </div>
      <div style={{ marginTop: "20px" }}>
        <RoundedButton
          icon={<span className="material-symbols-outlined">send</span>}
          onClick={() => {
            navigate("/contactanos");
          }}
          text={"Contáctanos"}
        />
      </div>
    </div>
  );
}
