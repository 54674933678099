import RoundedButton from "../RoundedButton/RoundedButton";
import "./InfoChip.css";

type InfoChipParams = {
  title: string;
  description: string;
  actionButtontext: string;
  actionButtonClick: any;
  backgroundImage: string;
};
export default function InfoChip({
  title,
  description,
  backgroundImage,
  actionButtontext,
  actionButtonClick,
}: InfoChipParams) {
  return (
    <div
      className="InfoChip--MainContainer"
      style={{
        backgroundImage: backgroundImage,
        backgroundRepeat: "no-repeat",
      }}
    >
      <div style={{zIndex:1}}>
        <text
          style={{
            fontFamily: "Montserrat",
            fontSize: "24px",
            color: "#FFB800",
          }}
        >
          {title}
        </text>
      </div>

      <div style={{zIndex:1}}>
        <text
          style={{
            fontFamily: "Montserrat",
            fontSize: "16px",
            color: "white",
            marginBottom: "20px",
          }}
        >
          {description}
        </text>
      </div>
      <div >
        <img className="InfoChip--BackgroundImage" src={backgroundImage} alt="" />
      </div>
      <div style={{zIndex:1}}>

      <RoundedButton text={actionButtontext} onClick={() => {}} />
      </div>
    </div>
  );
}
